<template>
  <NuxtLayout name="activity">
    <!-- style="width: 1092px;" -->
    <div class="page-login w"  :class="{ hnkjspx: hrefUrl == 'hnkdtdxlkcxx.ykumda.com' }">
      <div class="left" v-if="hrefUrl != 'hnkdtdxlkcxx.ykumda.com' && hrefUrl != 'hnkdtdxlkspx.ykumda.com' && false">
        <img src="@/assets/images/login/left_img.png" alt="" />
      </div>

      <div v-else>
        <!-- <img src="@/assets/images/login/left_imgHnkj.png" alt="" /> -->
      </div>
      <div v-if="loginPlat === 1" class="form_container">
        <!-- <img class="login_plat" src="@/assets/images/login/app.png" alt="" /> -->
        <!-- <div
          v-if="shopConfig.enableWxPcLogin"
          class="tab_wrapper"
        >
          <img
            class="tab_img pointer"
            src="@/assets/images/login/app_1.png"
            alt=""
            @click="handleChangePlat(2)"
          >
          <div class="login_tips">
            微信登录
          </div>
        </div> -->
        <el-tabs v-model="tabName" @change="tabChange">
          <el-tab-pane label="账号登录" name="1">
            <div class="pwdForm">
              <div class="form_box">
                <el-form ref="pwdFormRef" :model="pwdForm" :rules="pwdRules" class="demo-pwdForm" size="large">
                  <client-only>
                    <el-form-item prop="phone">
                      <div class="prefix_select_input">
                        <el-input v-model="pwdForm.phone" placeholder="请输入手机号" @keyup.enter="submitForm(pwdFormRef)">
                          <template #prefix>
                            <el-popover ref="choosePop1" placement="bottom-start" :width="380" trigger="click"
                              :teleported="false">
                              <template #reference>
                                <span class="c_333 pointer pop_holder" @click="openPopver">
                                  <span class="ellipsis">+{{ pwdForm.telCode }}</span>
                                  <i class="arrows-bottom" />
                                </span>
                              </template>
                              <el-scrollbar>
                                <div class="te_code_enum">
                                  <div v-for="item in opts.TelCodeEnum" :key="item.standardDesc"
                                    class="te_code_enum_item" @click="handleSelectTelCode(item)">
                                    <span>
                                      <span class="mgr10"> {{ item.codeDesc }}</span>
                                      <span>{{ item.standardDesc }}</span>
                                    </span>
                                    <i v-if="
                                      item.code + item.standardDesc ===
                                      pwdForm.telCode + opts.country
                                    " class="iconfont icon-correct blue font12" />
                                  </div>
                                </div>
                              </el-scrollbar>
                            </el-popover>
                          </template>
                        </el-input>
                      </div>
                    </el-form-item>
                    <el-form-item prop="loginPwd">
                      <el-input v-model="pwdForm.loginPwd" type="password" show-password placeholder="请输入密码"
                        @keyup.enter="submitForm(pwdFormRef)">
                        <template #prefix>
                          <el-icon class="el-input__icon">
                            <lock />
                          </el-icon>
                        </template>
                      </el-input>
                    </el-form-item>
                    <el-form-item v-if="imgVerification.img" prop="imageVerification">
                      <div class="img_verification">
                        <el-input v-model="pwdForm.imageVerification" placeholder="请输入验证码"
                          @keyup.enter="submitForm(pwdFormRef)" />
                        <img :src="imgVerification.img" alt="" @click="getImgVerification" />
                      </div>
                    </el-form-item>
                  </client-only>
                </el-form>
                <div class="row" prop="rememberLogin">
                  <el-checkbox style="margin-left: 20px; z-index: 999" v-model="pwdForm.isAgreement" size="default">
                    我已阅读并同意<span class="blue_text" @click="agreementModel.visible = true">《用户协议》</span>
                  </el-checkbox>
                  <div class="btn">
                    <NuxtLink to="/resetpwd">
                      <span class="blue">忘记密码？</span>
                    </NuxtLink>
                  </div>
                </div>
              </div>
              <el-button class="submit" type="primary" @keyup.enter="submitForm(pwdFormRef)"
                @click="submitForm(pwdFormRef)">
                登录
              </el-button>
              <div v-if="
                nav &&
                nav.userRegister &&
                store.shopData &&
                store.shopData.config.allowUserRegister
              " class="btn register_btn">
                <NuxtLink to="/register">
                  <span class="blue">没有账号，我要注册 ></span>
                </NuxtLink>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="短信登录" name="2">
            <div class="pwdForm">
              <div class="form_box">
                <el-form ref="codeFormRef" :model="pwdForm" :rules="codeRules" class="demo-pwdForm" size="large">
                  <el-form-item prop="telCode">
                    <div class="prefix_select_input">
                      <el-input v-model="pwdForm.phone" placeholder="请输入手机号" @keyup.enter="submitForm(codeFormRef)">
                        <template #prefix>
                          <client-only>
                            <el-popover ref="choosePop2" placement="bottom-start" :width="380" trigger="click"
                              :teleported="false">
                              <template #reference>
                                <span class="c_333 pointer pop_holder" @click="openPopver">
                                  <span class="ellipsis">+{{ pwdForm.telCode }}</span>
                                  <i class="arrows-bottom" />
                                </span>
                              </template>
                              <el-scrollbar>
                                <div class="te_code_enum">
                                  <div v-for="item in opts.TelCodeEnum" :key="item.standardDesc"
                                    class="te_code_enum_item" @click="handleSelectTelCode(item)">
                                    <span>
                                      <span class="mgr10"> {{ item.codeDesc }}</span>
                                      <span>{{ item.standardDesc }}</span>
                                    </span>
                                    <i v-if="
                                      item.code + item.standardDesc ===
                                      pwdForm.telCode + opts.country
                                    " class="iconfont icon-correct blue font12" />
                                  </div>
                                </div>
                              </el-scrollbar>
                            </el-popover>
                          </client-only>
                        </template>
                      </el-input>
                    </div>
                  </el-form-item>
                  <el-form-item prop="verificationCode">
                    <el-input v-model="pwdForm.verificationCode" placeholder="短信验证码"
                      @keyup.enter="submitForm(codeFormRef)">
                      <template #prefix>
                        <span class="iconfont icon-comment" />
                      </template>
                      <template #suffix>
                        <el-button type="primary" @click="getCode">
                          {{ txt }}
                        </el-button>
                      </template>
                    </el-input>
                  </el-form-item>
                  <el-form-item v-if="imgVerification.img" prop="imageVerification">
                    <div class="img_verification">
                      <el-input v-model="pwdForm.imageVerification" placeholder="请输入验证码"
                        @keyup.enter="submitForm(codeFormRef)" />
                      <img :src="imgVerification.img" alt="" @click="getImgVerification" />
                    </div>
                  </el-form-item>
                </el-form>
                <div class="row">
                  <el-checkbox style="margin-left: 20px;z-index: 999;" v-model="pwdForm.isAgreement" size="default">

                    我已阅读并同意<span class="blue_text" @click="agreementModel.visible = true">《用户协议》</span>
                  </el-checkbox>
                  <div class="btn">
                    <Nuxt-Link to="/resetpwd">
                      <span class="blue">忘记密码？</span>
                    </Nuxt-Link>
                  </div>
                </div>
              </div>
              <el-button class="submit" type="primary" @click="submitForm(codeFormRef)">
                登录
              </el-button>
              <div v-if="
                nav &&
                nav.userRegister &&
                store.shopData &&
                store.shopData.config.allowUserRegister
              " class="btn register_btn">
                <NuxtLink to="/register">
                  <span class="blue">没有账号，我要注册 ></span>
                </NuxtLink>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div class="other_login" v-if="showLogin">
          <p>第三方账号快速登录</p>
          <div class="icon_list">
            <img src="@/assets/images/login/icon_weixin.svg" alt="" @click="handleChangePlat(2)" />
            <!-- <img src="@/assets/images/login/icon_qq.png" alt="" @click="handleChangeQQ"/> -->
            <!-- <a href="{$openLoginUrl.connectQQ}" class="icon connect-qq"><span icon-bg2="icon_qq_n"></span>  QQ登录</a>  -->
            <!-- <svg class="icondx" @click="qqlogin"  aria-hidden="true">
       <use   xlink:href="#icon-QQ1"></use>
</svg> -->
          </div>
          <!-- <div class="icon_list" >
            <img
              v-for="(item, index) in loginIconList"
              :src="item.icon"
              :key="index"
              alt=""
            />
          </div> -->
        </div>
      </div>
      <div v-if="loginPlat === 2" class="form_container">
        <!-- <img class="login_plat" src="@/assets/images/login/pc.png" alt="" /> -->
        <div class="tab_wrapper">
          <img class="tab_img pointer" src="@/assets/images/login/pc_1.png" alt="" @click="handleChangePlat(1)" />
          <div class="login_tips">账号登录</div>
        </div>
        <div v-if="!bindingWxModel.visible" class="drcode">
          <iframe v-if="wxLoginUrl" class="qr_code_iframe" :src="wxLoginUrl" frameborder="0" />
          <!-- <qrcode class="qr_code" v-if="wxLoginUrl" :value="wxLoginUrl" :size="200" alt="微信扫码登录" /> -->
          <div class="desc">
            <p>微信登录成功即代表同意《用户协议》</p>
          </div>
        </div>
        <binding-wx v-model="bindingWxModel.visible" :user-info="bindingWxModel.userInfo" :title="bindingWxModel.title"
          @callback="hanldeClosebindingWxModel" />
        <!-- <div class="other_login">
          <p>其他登录方式</p>
          <div class="icon_list">
            <img
              v-for="(item, index) in loginIconList"
              :src="item.icon"
              alt=""
              :key="index"
            />
          </div>
        </div> -->
      </div>
      <client-only>
        <agreement v-model="agreementModel.visible" />
      </client-only>
    </div>
  </NuxtLayout>
</template>
<script setup>
import login_wx from "../../assets/images/login/icon_wx.png";
import login_qq from "../../assets/images/login/icon_qq.png";
import { ElMessage } from "element-plus";
import { Iphone, Lock } from "@element-plus/icons-vue";
import { useUserStore } from "@/stores/user";
import { useNavStore } from "@/stores/nav";
import {
  getMsg,
  userLoginPwd,
  userLoginCode,
  getImageVerification,
} from "@/api/user";
import { setSession, getStore, setStore } from "@/utils/storage";
import { getWxAuthLoginUrl, wxLogin } from "@/api/system";
import bindingWx from "@/components/login/bindingWx.vue";
import config from "@/config";
import cookie from "@/utils/cookies";
import { shopDataInfo } from "@/api/system";
const { data: shopData } = await useAsyncData("course", async (ctx) => {
  let url = "";
  if (process.server) {
    url = ctx.ssrContext?.event?.req?.headers?.host;
  } else {
    url = window.location.host;
  }
  if (config.url) {
    url = config.url;
  }
  return await shopDataInfo({ domain: url });
});

const cookieData = cookie.getShop();
const hrefUrl = ref("");

useHead({
  title: "登录中心-" + cookieData.shopName,
});
const isContract = ref(false);
const store = useUserStore();
const navStore = useNavStore();
const nav = navStore.nav;
const { getEnumList, setToken, getUserView, getShopInfo } = store;
const router = useRouter();
const route = useRoute();
const purchaseSwitch = computed(() => {
  const priceSwitch = shopData.value?.config?.purchaseSwitch;
  return priceSwitch == undefined || priceSwitch == null ? true : priceSwitch;
});
const agreementModel = reactive({
  visible: false,
});
const loginPlat = ref(1);
const tabName = ref("1");
const tabChange = (type) => {
  if (type === "1") {
  } else {
  }
};
const errorNum = ref(0); // 记录登录错误次数，超过两次须填写图形验证码校验
const opts = reactive({
  TelCodeEnum: "",
  country: "中国",
});
let ipInfo = reactive({});
const wxLoginUrl = ref("");
const handleChangePlat = (type) => {
  const redirectUrl = config.url
    ? window.location.protocol + "//" + config.url
    : window.location.href;
  loginPlat.value = type;
  if (type === 2) {
    getWxAuthLoginUrl({ redirectUrl, authType: 1 }).then((res) => {
      if (res) {
        wxLoginUrl.value = res;
      }
    });
  }
};
const handleChangeQQ = () => {
  location.href =
    "https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=102024981&redirect_uri=http://huchunlin.cn/loginserver/wxlogin&state=Vr12qQmurwoRYeV7";
  // location.href="https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=102068985&redirect_uri=https://edu.shanxiyixue.com/mycb&state=rmBNL4ZpqeDXdU7W"
  // https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=102068985&redirect_uri=https://edu.shanxiyixue.com/mycb&state=rmBNL4ZpqeDXdU7W
};
// const qqlogin =() =>{
//   location.href="https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=102024981&redirect_uri=http://huchunlin.cn/loginserver/wxlogin&state=Vr12qQmurwoRYeV7"
// }
const loginIconList = [
  {
    icon: login_wx,
    name: "WeiXin",
  },
  // {
  //   icon: '../../assets/images/login/icon_wb.png',
  //   name: 'WeiBo'
  // },
  {
    icon: login_qq,
    name: "QQ",
  },
  // {
  //   icon: '../../assets/images/login/icon_zfb.png',
  //   name: 'Alipay'
  // }
];

const imgVerification = reactive({
  img: "",
  token: "",
});

const pwdFormRef = ref("pwdFormRef");
const pwdForm = reactive({
  telCode: "86",
  phone: "",
  loginPwd: "",
  verificationCode: "",
  imageVerification: "",
  isAgreement: false,
});

const pwdRules = reactive({
  telCode: [
    {
      required: true,
      message: "请选择国家/地区",
      trigger: "change",
    },
  ],
  phone: [
    {
      required: true,
      message: "请输入手机号",
      trigger: "blur",
    },
  ],
  loginPwd: [
    {
      required: true,
      message: "请输入密码",
      trigger: "blur",
    },
  ],
  imageVerification: [
    {
      required: true,
      message: "请输入图形验证码",
      trigger: "blur",
    },
  ],
});

const codeFormRef = ref("codeFormRef");

const codeRules = reactive({
  telCode: [
    {
      required: true,
      message: "请选择国家/地区",
      trigger: "change",
    },
  ],
  phone: [
    {
      required: true,
      message: "请输入手机号",
      trigger: "blur",
    },
  ],
  verificationCode: [
    {
      required: true,
      message: "请输入短信验证码",
      trigger: "blur",
    },
  ],
  imageVerification: [
    {
      required: true,
      message: "请输入图形验证码",
      trigger: "blur",
    },
  ],
});
const shopConfig = ref({});
const wxCode = ref("");
const showLogin = ref(true);
// setSession("userInfo", "");
onMounted(() => {
  if (window.location.hostname == "hnkdtdxlkspx.ykumda.com" ||
    window.location.hostname == 'hnkdtdxlkcxx.ykumda.com' ||
    window.location.hostname == 'train.shanxiyixue.com' ||
    window.location.hostname == 'aufe.ykumda.com' ||
    window.location.hostname == 'cjdxyx.ykumda.com' ||
    window.location.hostname == 'cjdxtk.ykumda.com') {
    showLogin.value = false;
  }
  wxCode.value = route.query.code;
  hrefUrl.value = window.location.hostname;
  if (wxCode.value) {
    loginPlat.value = 2;
    wxCodeLogin();
  }
  errorNum.value = parseInt(getStore("errorNum")) || 0;
  setSession("userInfo", "");
  if (errorNum.value >= 2) {
    // 获取图形验证码
    getImgVerification();
  }
  jsonp("//gateway.doityun.com/ip/info", (res) => {
    ipInfo = res;
    setSession("ipInfo", ipInfo);
  });
  getEnumList("TelCodeEnum", "arr").then((res) => {
    opts.TelCodeEnum = res;
  });
  getShopInfo().then((res) => {
    shopConfig.value = res.config;
  });
});
const bindingWxModel = reactive({
  visible: false,
  title: "绑定手机号",
  userInfo: {},
});

const hanldeClosebindingWxModel = async () => {
  bindingWxModel.visible = false;
  const userInfo = await getUserView();
  if (userInfo) {
    if (
      shopData.value.enabledBranchShop == true &&
      shopData.value.contractExist == true &&
      !userInfo.isReadContract
    ) {
      router.replace({ path: "/contract" });
    } else {
      if (route.query.redirect) {
        let redirectUrl = decodeURIComponent(route.query.redirect);
        if (redirectUrl.indexOf("?") !== -1) {
          const redirectUrlArr = redirectUrl.split("?");
          redirectUrl = redirectUrlArr[0];
          const query = {};
          const queryArr = redirectUrlArr[1].split("&");
          for (let i = 0; i < queryArr.length; i++) {
            const keyValue = queryArr[i].split("=");
            query[keyValue[0]] = keyValue[1];
          }
          router.replace({ path: redirectUrl, query });
        } else {
          router.replace({ path: redirectUrl });
        }
      } else {
        router.replace({ path: "/" });
      }
    }
  }
};
const wxCodeLogin = async () => {
  const data = {};
  data.loginIp = ipInfo.ip;
  data.city = ipInfo.city;
  const { name: osName, version: osVersion } = getOsInfo();
  const { name: browserName, version: browserVersion } = getBrowserInfo();
  data.os = osName;
  data.osVersion = osVersion;
  data.browser = browserName;
  data.browserVersion = browserVersion;
  wxLogin({ ...data, authType: 1, code: wxCode.value, clientType: 1 }).then(
    async (res) => {
      if (res) {
        if (res.bindUser) {
          setToken(res.token);
          const userInfo = await getUserView();
          if (userInfo) {
            if (
              shopData.value.enabledBranchShop == true &&
              shopData.value.contractExist == true &&
              !userInfo.isReadContract
            ) {
              router.replace({ path: "/contract" });
            } else {
              if (route.query.redirect) {
                let redirectUrl = decodeURIComponent(route.query.redirect);
                if (redirectUrl.indexOf("?") !== -1) {
                  const redirectUrlArr = redirectUrl.split("?");
                  redirectUrl = redirectUrlArr[0];
                  const query = {};
                  const queryArr = redirectUrlArr[1].split("&");
                  for (let i = 0; i < queryArr.length; i++) {
                    const keyValue = queryArr[i].split("=");
                    query[keyValue[0]] = keyValue[1];
                  }
                  router.replace({ path: redirectUrl, query });
                } else {
                  router.replace({ path: redirectUrl });
                }
              } else {
                router.replace({ path: "/" });
              }
            }
          }
        } else {
          //         bindingWxModel.userInfo = {
          //           gender: 1,
          // headImg: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTKr2HLyKK8n9xTnHGnqg7OZHV393Ynty7EniagIcYichEictnE65X3WFmN26UTmWswhCql0Q0vbn38zw/132",
          // nickname: "_@",
          // unionId: "o-s341LL6PQtbxNc2sPH1rAaH7QQ"
          //         }
          bindingWxModel.userInfo = res.authInfo;
          bindingWxModel.visible = true;
          bindingWxModel.title = "绑定手机号";
        }
      }
    }
  );
};
const choosePop1 = ref(null);
const choosePop2 = ref(null);
const openPopver = () => {
  const activeIndex = opts.TelCodeEnum.findIndex((item) => {
    return item.code + item.standardDesc === pwdForm.telCode + opts.country;
  });
  const activeItem = opts.TelCodeEnum.splice(activeIndex, 1);
  opts.TelCodeEnum.unshift(activeItem[0]);
};
const handleSelectTelCode = (row) => {
  pwdForm.telCode = row.code;
  opts.country = row.standardDesc;
  choosePop1.value.hide();
  choosePop2.value.hide();
};

const generateJsonpCallback = () => {
  return `jsonpcallback_${Date.now()}_${Math.floor(Math.random() * 100000)}`;
};

const jsonp = (url, callback) => {
  const funcName = generateJsonpCallback();
  window[funcName] = callback;
  const script = document.createElement("script");
  script.src = `${url}?callback=${funcName}`;
  script.type = "text/javascript";
  document.body.appendChild(script);
};

// 获取系统信息
const getOsInfo = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  let name = "Unknown";
  let version = "Unknown";
  if (userAgent.indexOf("win") > -1) {
    name = "Windows";
    if (userAgent.indexOf("windows nt 5.0") > -1) {
      version = "Windows 2000";
    } else if (
      userAgent.indexOf("windows nt 5.1") > -1 ||
      userAgent.indexOf("windows nt 5.2") > -1
    ) {
      version = "Windows XP";
    } else if (userAgent.indexOf("windows nt 6.0") > -1) {
      version = "Windows Vista";
    } else if (
      userAgent.indexOf("windows nt 6.1") > -1 ||
      userAgent.indexOf("windows 7") > -1
    ) {
      version = "Windows 7";
    } else if (
      userAgent.indexOf("windows nt 6.2") > -1 ||
      userAgent.indexOf("windows 8") > -1
    ) {
      version = "Windows 8";
    } else if (userAgent.indexOf("windows nt 6.3") > -1) {
      version = "Windows 8.1";
    } else if (
      userAgent.indexOf("windows nt 6.2") > -1 ||
      userAgent.indexOf("windows nt 10.0") > -1
    ) {
      version = "Windows 10";
    } else {
      version = "Unknown";
    }
  } else if (userAgent.indexOf("iphone") > -1) {
    name = "Iphone";
  } else if (userAgent.indexOf("mac") > -1) {
    name = "Mac";
  } else if (
    userAgent.indexOf("x11") > -1 ||
    userAgent.indexOf("unix") > -1 ||
    userAgent.indexOf("sunname") > -1 ||
    userAgent.indexOf("bsd") > -1
  ) {
    name = "Unix";
  } else if (userAgent.indexOf("linux") > -1) {
    if (userAgent.indexOf("android") > -1) {
      name = "Android";
    } else {
      name = "Linux";
    }
  } else {
    name = "Unknown";
  }
  return {
    name,
    version,
  };
};
const getBrowserInfo = () => {
  /* eslint-disable */
  const Sys = {};
  const ua = navigator.userAgent.toLowerCase();
  let s;
  (s = ua.match(/rv:([\d.]+)\) like gecko/))
    ? (Sys.ie = s[1])
    : (s = ua.match(/msie ([\d]+)/))
      ? (Sys.ie = s[1])
      : (s = ua.match(/edge\/([\d]+)/))
        ? (Sys.edge = s[1])
        : (s = ua.match(/firefox\/([\d]+)/))
          ? (Sys.firefox = s[1])
          : (s = ua.match(/(?:opera|opr).([\d]+)/))
            ? (Sys.opera = s[1])
            : (s = ua.match(/chrome\/([\d]+)/))
              ? (Sys.chrome = s[1])
              : (s = ua.match(/version\/([\d]+).*safari/))
                ? (Sys.safari = s[1])
                : 0;
  // 根据关系进行判断
  if (Sys.ie)
    return {
      name: "IE",
      version: Sys.ie,
    };
  if (Sys.edge)
    return {
      name: "EDGE",
      version: Sys.edge,
    };
  if (Sys.firefox)
    return {
      name: "Firefox",
      version: Sys.firefox,
    };
  if (Sys.chrome)
    return {
      name: "Chrome",
      version: Sys.chrome,
    };
  if (Sys.opera)
    return {
      name: "Opera",
      version: Sys.opera,
    };
  if (Sys.safari)
    return {
      name: "Safari",
      version: Sys.safari,
    };
  return {
    name: "Unkonwn",
    version: "0.0.0",
  };
};

const txt = ref("获取验证码");
const disabled = ref(false);
const timeOut = () => {
  let num = 60;
  disabled.value = true;
  txt.value = num;
  let nt = setInterval(() => {
    if (num <= 0) {
      clearInterval(nt);
      disabled.value = false;
      txt.value = "重新获取";
    } else {
      num--;
      txt.value = num + "s";
    }
  }, 1000);
};

const getCode = () => {
  if (disabled.value) return;
  if (!/^1\d{10,10}$/.test(parseInt(pwdForm.phone))) {
    ElMessage.error("请输入正确的手机号");
    return false;
  }
  getMsg({
    phone: pwdForm.phone,
    telCode: pwdForm.telCode,
    verificationCodeType: 2,
  }).then((res) => {
    timeOut();
  });
};

const submitForm = async (formEl) => {
  const data = {};
  if (!pwdForm.isAgreement) {
    ElMessage.warning("请阅读并同意用户协议");
    return;
  }
  data.loginIp = ipInfo.ip;
  data.city = ipInfo.city;
  const { name: osName, version: osVersion } = getOsInfo();
  const { name: browserName, version: browserVersion } = getBrowserInfo();
  data.os = osName;
  data.osVersion = osVersion;
  data.browser = browserName;
  data.browserVersion = browserVersion;
  if (!formEl) return;
  formEl.validate((valid) => {
    if (valid) {
      if (tabName.value === "1") {
        if (imgVerification.token) {
          data.imageVerificationToken = imgVerification.token;
          data.imageVerification = pwdForm.imageVerification;
        }
        userLoginPwd({
          clientType: 1,
          ...pwdForm,
          ...data,
        })
          .then(async (res) => {
            if (res && res.token) {
              errorNum.value = 0;
              setStore("errorNum", errorNum.value);
              setToken(res.token);
              const userInfo = await getUserView();
              if (userInfo) {
                // !userInfo.isReadContract
                if (
                  shopData.value.enabledBranchShop == true &&
                  shopData.value.contractExist == true &&
                  !userInfo.isReadContract
                ) {
                  router.replace({ path: "/contract" });
                } else {
                  if (route.query.redirect ||
                    window.location.hostname == "hnkdtdxlkspx.ykumda.com" ||
                    window.location.hostname == 'hnkdtdxlkcxx.ykumda.com' ||
                    window.location.hostname == 'aufe.ykumda.com' ||
                    window.location.hostname == 'cjdxyx.ykumda.com' ||
                    window.location.hostname == 'cjdxtk.ykumda.com') {
                    let redirectUrl = decodeURIComponent(route.query.redirect);
                    if (redirectUrl.indexOf("?") !== -1) {

                      const redirectUrlArr = redirectUrl.split("?");
                      redirectUrl = redirectUrlArr[0];
                      const query = {};
                      const queryArr = redirectUrlArr[1].split("&");
                      for (let i = 0; i < queryArr.length; i++) {
                        const keyValue = queryArr[i].split("=");
                        query[keyValue[0]] = keyValue[1];
                      }
                      router.replace({ path: redirectUrl, query });
                    } else if (!purchaseSwitch.value) {
                      router.replace({ path: "/account/course" });
                      // 当redirectUrl 有值 并且 redirectUrl不等于 undefined
                    } else if (redirectUrl && redirectUrl != 'undefined') {
                      router.replace({ path: redirectUrl });
                    } else {
                      router.replace({ path: "/account/course" });
                    }
                  } else {
                    router.replace({ path: "/" });
                  }
                }
              }
            }
          })
          .catch((err) => {
            if (err.code === 999) {
              errorNum.value += 1;
              setStore("errorNum", errorNum.value);
              if (errorNum.value >= 2 || imgVerification.token) {
                // 获取图形验证码
                getImgVerification();
              }
            } else if (err.code === 321) {
              getImgVerification();
            }
          });
      } else {
        if (imgVerification.token) {
          data.imageVerificationToken = imgVerification.token;
          data.imageVerification = pwdForm.imageVerification;
        }
        userLoginCode({
          clientType: 1,
          ...pwdForm,
          ...data,
        })
          .then(async (res) => {
            if (res && res.token) {
              errorNum.value = 0;
              setStore("errorNum", errorNum.value);
              setToken(res.token);
              const userInfo = await getUserView();
              if (userInfo) {
                if (
                  shopData.value.enabledBranchShop == true &&
                  shopData.value.contractExist == true &&
                  !userInfo.isReadContract
                ) {
                  router.replace({ path: "/contract" });
                } else {
                  if (route.query.redirect || window.location.hostname == "hnkdtdxlkspx.ykumda.com" || window.location.hostname == 'hnkdtdxlkcxx.ykumda.com') {
                    router.push({
                      path: decodeURIComponent(route.query.redirect),
                    });
                  } else if (!purchaseSwitch.value) {
                    router.replace({ path: "/account/course" });
                  } else {
                    router.push({ path: "/" });
                  }
                }
              }
            }
          })
          .catch((err) => {
            if (err.code === 999) {
              errorNum.value += 1;
              setStore("errorNum", errorNum.value);
              if (errorNum.value >= 2 || imgVerification.token) {
                // 获取图形验证码
                getImgVerification();
              }
            } else if (err.code === 321) {
              getImgVerification();
            }
          });
      }
    } else {
      return false;
    }
  });
};
const getImgVerification = () => {
  getImageVerification().then((res) => {
    if (res && res.token) {
      imgVerification.img = res.img;
      imgVerification.token = res.token;
    }
  });
};
</script>
<style lang="scss">
.icon-comment {
  position: relative;
  left: -1px;
  top: 1px;
}

.page-login {
  .el-tabs__item {
    font-size: 16px;
    font-weight: 700;
  }

  .el-tabs__nav-wrap::after {
    background-color: #fff;
  }

  .el-tabs__active-bar {
    width: 10px !important;
    height: 3px;
    border-radius: 2px;
    left: 27px;
  }

  .el-select {
    width: 100%;

    .el-input__inner {
      text-align: right;
    }
  }

  .el-input__icon {
    color: #333;
  }
}
</style>
<style lang="scss" scoped>
.page-login {
  height: calc(100vh - 66px - 70px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 60px;

  .form_box {
    height: 230px;
  }

  .demo-pwdForm {
    min-height: 120px;
  }

  .form_container {
    width: 440px;
    // height: 470px;
    // height: 500px;
    height: 510px;
    border-radius: 10px;
    background: #fff;
    padding: 30px;
    padding-top: 20px;
    position: relative;

    .title {
      font-size: 16px;
      font-weight: 700;
    }

    .drcode {
      text-align: center;

      img {
        margin: auto;
      }

      .desc {
        margin-top: 20px;

        b {
          color: #2256f6;
        }
      }
    }

    span {
      color: #333;
    }

    .login_plat {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;

      a {
        margin-left: 5px;
      }
    }

    .btn {
      font-size: 14px !important;
    }

    .blue {
      color: #2256f6 !important;
    }

    .register_btn {
      text-align: center;
      margin-top: 20px;
    }

    .remember_checkbox {
      :deep(.el-checkbox__label) {
        font-size: 14px;
      }
    }

    .img_verification {
      display: flex;
      justify-content: space-between;

      img {
        width: 185px;
        height: 40px;
        margin-left: 20px;
      }
    }

    .submit {
      width: 100%;
      height: 50px;
      // margin-top: 14px;
      // margin-top: 54px;
      margin-top: 80px;
      font-size: 16px;
    }

    .other_login {
      position: absolute;
      left: 0;
      // bottom: 30px;
      // bottom: 100px;
      bottom: 120px;
      width: 100%;
      text-align: center;

      .icon_list {
        margin-top: 10px;
        display: flex;
        justify-content: center;

        img {
          // height: 30px;
          // width: 30px;
          margin: 0 10px;
          cursor: pointer;
        }
      }
    }
  }
}

.pwdForm {
  min-height: 316px;
  margin-top: 20px;
}

.blue_text {
  color: #2256f6 !important;
}

.qr_code {
  margin: 0 auto;
}

.tab_wrapper {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  .login_tips {
    position: relative;
    left: 4px;
    padding: 4px 8px;
    color: var(--el-color-primary);
    font-size: 13px;
    line-height: 17px;
    background: #fff;
    border: 1px solid rgba(34, 86, 246, 0.3);
    border-radius: 6px;

    &::after {
      content: "";
      position: absolute;
      right: -11px;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 10px;
      height: 10px;
      border: 1px solid rgba(34, 86, 246, 0.3);
      border-left: none;
      border-bottom: none;
      background: #fff;
    }
  }
}

.qr_code_iframe {
  width: 100%;
  height: 400px;
}

//修改选中后文本的颜色
:deep(.el-checkbox__input.is-checked + .el-checkbox__label) {
  color: #333 !important;
}

.hnkjspx {
  width: auto;
  margin: auto;
}
</style>
